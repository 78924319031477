import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import Layout from '../containers/Layout/Layout'
import MainPage from '../containers/MainPage/MainPage'
import NotFound404 from '../containers/NotFound/NotFound404'
import isLogin from '../utils/enums/authority'

const PrivateRoute = ({ alertMessage = '로그인 후 이용가능합니다.' }) => {
  if (isMobile || !isLogin()) {
    return <Navigate to="/" replace state={{ alertMessage }} />
  }

  return <Outlet />
}

// 긴급 점검일때는 url 막아야 함
const Router = () => {
  return (
    <AnimatePresence>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<MainPage />} />
        </Route>
        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </AnimatePresence>
  )
}

export default Router
