import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { colorWhite } from '@/utils/palette'
import { Button } from '../../shared/components/Button'
// import { Button } from '@/shared/components/Button'

const NotFound404 = () => (
  <NotFoundContainer>
    <NotFountContent>
      {/* <NotFoundImage src={`${process.env.PUBLIC_URL}/img/404/404.png`} alt="404" /> */}
      <NotFoundInfo>원하시는 페이지를 찾을 수 없습니다. </NotFoundInfo>
      <NotFoundInfo2>찾으려는 페이지의 주소가 잘못 입력되었거나,</NotFoundInfo2>
      <NotFoundInfo2>주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.</NotFoundInfo2>
      <NotFoundInfo2>입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주세요.</NotFoundInfo2>
      <Button as={Link} variant="primary" to="/">
        홈으로 이동
      </Button>
    </NotFountContent>
  </NotFoundContainer>
)

export default NotFound404

// region STYLES

const NotFoundContainer = styled.div`
  text-align: center;
  height: 100vh;
  overflow: auto;
  display: flex;
  background: url(${process.env.PUBLIC_URL}/img/404/bg_404.jpeg) no-repeat center;
  background-size: cover;

  button {
    margin: 0;
  }
`

const NotFountContent = styled.div`
  margin: auto;
  padding: 10px;
`

const NotFoundImage = styled.img`
  max-width: 500px;
  width: 100%;
`

const NotFoundInfo = styled.h3`
  color: white;
  margin-bottom: 20px;
  margin-top: 90px;

  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

const NotFoundInfo2 = styled.h4`
  color: white;
  margin-bottom: 15px;
  margin-top: 10px;

  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

// endregion
