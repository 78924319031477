import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import store from '../redux/store'
import Router from './Router'
import GlobalStyles from './globalStyles'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
          <GlobalStyles />
          <Router />
        </Provider>
      </BrowserRouter>
    </>
  )
}

export default App
