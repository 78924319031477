import React from 'react'
import styled from 'styled-components'

const MainPage = () => {
  const onClickHandler = path => {
    window.open(path, '_self')
  }

  return (
    <MainPageWrap>
      <MainPageImg src={`${process.env.PUBLIC_URL}/img/main/main-background.png`} />
      <MainPageTextImg src={`${process.env.PUBLIC_URL}/img/main/main-text.gif`} />
      {/* <MainPageForeverText>네네평생안전.COM</MainPageForeverText> */}
      <MainPageUrlBox>
        <MainPageUrlText
          onClick={() => {
            onClickHandler('https://nene72.com')
          }}
        >
          https://nene72.com
        </MainPageUrlText>
      </MainPageUrlBox>
      <MainPageUrlBox2>
        <MainPageUrlText
          onClick={() => {
            onClickHandler('https://ne-7272.com')
          }}
        >
          https://ne-7272.com
        </MainPageUrlText>
      </MainPageUrlBox2>

      <MainPageTelegramText
        onClick={() => {
          window.open('https://t.me/nene7272', '_blank')
        }}
      />
    </MainPageWrap>
  )
}

export default MainPage

const MainPageWrap = styled.div`
  width: 100%;
  position: relative;
`

const MainPageImg = styled.img`
  width: 100%;
  min-height: auto;
`

const MainPageTextImg = styled.img`
  width: 60%;
  min-height: auto;
  position: absolute;
  top: 32.5%;
  left: 50%;
  transform: translate(-50%, -32.5%);
`

const MainPageUrlBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  @media all and (min-width: 768px) {
    font-size: 30px;
    top: 44.2%;
    transform: translateY(44.2%);
  }

  @media all and (min-width: 480px) and (max-width: 767px) {
    font-size: 23px;
    top: 44%;
    transform: translateY(44%);
  }

  @media all and (max-width: 479px) {
    font-size: 14px;
    top: 44%;
    transform: translateY(44%);
  }
`

const MainPageUrlText = styled.span`
  color: #fff;
  cursor: pointer;
`

const MainPageUrlBox2 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  @media all and (min-width: 768px) {
    font-size: 30px;
    top: 49.1%;
    transform: translateY(49.1%);
  }

  @media all and (min-width: 480px) and (max-width: 767px) {
    font-size: 23px;
    top: 49%;
    transform: translateY(49%);
  }

  @media all and (max-width: 479px) {
    font-size: 14px;
    top: 49%;
    transform: translateY(49%);
  }
`

const MainPageTelegramText = styled.div`
  width: 50%;
  height: 85px;
  cursor: pointer;
  position: absolute;
  top: 63.5%;
  left: 50%;
  transform: translate(-50%, -63.5%);

  @media all and (min-width: 480px) and (max-width: 767px) {
    height: 65px;
  }

  @media all and (max-width: 479px) {
    height: 45px;
  }
`
