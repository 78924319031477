import React from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

// TODO  https://fourwingsy.medium.com/%ED%8C%9D%EC%97%85%EC%99%80-%EB%A9%94%EC%8B%9C%EC%A7%80-with-react-afd1923797d8
const Layout = () => {
  return (
    <ContainerWrap className="game-container">
      <Container>
        <Outlet />
      </Container>
    </ContainerWrap>
  )
}

export default Layout

const ContainerWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // height: 100vh;
`

const ContainerSecondWrap = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll !important;
`

const Container = styled.div`
  width: 100%;
  min-width: 360px;
  max-width: 900px;

  margin: 0 auto;
`
