import { createGlobalStyle } from 'styled-components'

import { reset } from 'styled-reset'
import { colorMainBody, colorMainBodyBackground, scrollbarStyles } from '../utils/palette'

/**
 * @see : https://spring-in-wonderland.tistory.com/15 [스크롤]
 */

const GlobalStyles = createGlobalStyle`
  ${reset} 

  @font-face {
    font-family: 'The Jamsil';
    font-weight: 300;
    src: url(${process.env.PUBLIC_URL}/font/The-Jamsil-OTF-2-Light.otf) format('woff');
  }

  @font-face {
    font-family: 'The Jamsil';
    font-weight: 400;
    src: url(${process.env.PUBLIC_URL}/font/The-Jamsil-OTF-3-Regular.otf) format('woff');
  }
  @font-face {
    font-family: 'The Jamsil';
    font-weight: 500;
    src: url(${process.env.PUBLIC_URL}/font/The-Jamsil-OTF-4-Medium.otf) format('woff2');
  }



  *, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
  }

  html {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-text-size-adjust: 100%;
  }

  body {
    ${scrollbarStyles}
    font-family: The Jamsil;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    font-size: 12px;
    width: 100%;
    overflow: auto !important;
    height: 100vh;
    line-height: 1.42857143;
    background: ${colorMainBodyBackground};
    color: ${colorMainBody}
  }

  a {
    text-decoration: none;
  }
`

export default GlobalStyles
